exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/Classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/Help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-houses-js": () => import("./../../../src/pages/Houses.js" /* webpackChunkName: "component---src-pages-houses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newcomers-js": () => import("./../../../src/pages/Newcomers.js" /* webpackChunkName: "component---src-pages-newcomers-js" */),
  "component---src-pages-races-js": () => import("./../../../src/pages/Races.js" /* webpackChunkName: "component---src-pages-races-js" */),
  "component---src-pages-religions-js": () => import("./../../../src/pages/Religions.js" /* webpackChunkName: "component---src-pages-religions-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/Resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-world-js": () => import("./../../../src/pages/World.js" /* webpackChunkName: "component---src-pages-world-js" */)
}

